import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import '../../css/userHome.css';
import Button from 'react-bootstrap/Button';
import star from '../../assets/Paomedia-Small-N-Flat-Star.48.png';
import lightInverseLogo from '../../assets/BrandingLightTransparent.png';
import desktopHowTo from '../../assets/desktop_how_to_guide.png';
import mobileHowTo from '../../assets/mobile_how_to_guide.png';
import ApiClient from '../../util/ApiClient';
import NavDropdown from 'react-bootstrap/NavDropdown'
import update from 'immutability-helper';
import Modal from 'react-bootstrap/Modal'
import { Templates } from '../../components/Templates'
import config from '../../config';
import { Formik } from "formik";
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

import {
    isMobileOnly
} from "react-device-detect";
import Image from 'react-bootstrap/Image'
import { HubConnectionBuilder } from '@microsoft/signalr';
import standardPlus from '../../assets/standardPlus.png';
import proPlus from '../../assets/proPlus.png';
import Loader from "react-loader-spinner";
import dropin from 'braintree-web-drop-in'
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import infoLight from '../../assets/Info-Light-icon.png';
import { Finish } from '../../containers/createBot/Finish';
import {
    EmailIcon,
    FacebookIcon,
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
    RedditShareButton,
    RedditIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
import TiktokPixel from 'tiktok-pixel';

TiktokPixel.init('CG99DTBC77UCLSGQSU3G');

function ShowToolReservationFeeMessage(feeAmount) {
        return (
            <OverlayTrigger trigger="click" rootClose placement="left" overlay={<Popover id="popover-basic">
                <Popover.Title as="h3">Bot-It Reservation Service Fee</Popover.Title>
                <Popover.Content>
                    <div><p>
                        {"There will be a one-time successful reservation service fee of $" + feeAmount + " applied to this bot. This service fee will only apply if the bot is successful at completing all of the steps. Please contact us with any questions at info@botit.com"}
                    </p></div>
                </Popover.Content>
            </Popover>}>
                <img src={infoLight} id='tooltipfee' width={24} height={24} style={{ marginLeft: "10px" }} />
            </OverlayTrigger>
        )
}
function LiveBotFeedModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title style={props.connectionColor}>{props.connectionStatus}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>

                        <div style={{ whiteSpace: 'pre-wrap', maxHeight: '150px', width: '470', overflow: 'auto', display: 'flex', flexDirection: 'column-reverse' }}>
                            {props.liveFeedMessage}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
function DeleteBotModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <h5>Are you sure you want to delete '{props.botName}'?</h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant="danger" onClick={props.deleteBot} block>Yes Delete</Button>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={props.onHide}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
function DenyBotModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Confirm Deny</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <h5>Are you sure you want to deny '{props.denyBotName}'?</h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant="danger" onClick={props.denyBot} block>Yes</Button>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={props.onHide}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
function PauseBotModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Confirm Pause</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <p>Are you sure you want to pause '{props.botName}'? You can resume this Bot at any time after pausing.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant="danger" onClick={props.pauseBot} block>Yes Pause</Button>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={props.onHide}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
function CreateDuplicateBot(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Copy Bot</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Formik
                            initialValues={{
                                botName: "",
                                oGId: props.oGId,
                            }}
                            onSubmit={(values, actions) => {
                                ApiClient.post('bot/create/duplicate', {
                                    oGId: props.oGId,
                                    newBotName: values.botName,
                                }).then((response) => {
                                    actions.setSubmitting(false);
                                    values.botName = '';
                                    if (response.status == 200) {
                                        ApiClient.get('bot/me/all').then((response) => {
                                            props.updateBotDataFromDuplicate(response.data, response.data.length);
                                            props.onHide();
                                        }).catch((error) => {
                                            alert(error.response.error)
                                        });
                                    }

                                }).catch((error) => {
                                    actions.setSubmitting(false);
                                    values.botName = '';
                                })


                            }}
                        >
                            {formik => (
                                <div>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Form.Group controlId="botName">
                                            <Form.Label>New Bot Name</Form.Label>
                                            <Form.Control autoComplete={"off"} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.botName} type="text" placeholder="Bot Name" required />
                                            <span className={"text-muted"}>Copy '{props.mainBotDuplicateName}'</span>
                                        </Form.Group>

                                        <Row style={{ textAlign: 'center' }}>
                                            <Col lg={12}>
                                                <Button variant="primary" type="submit" disabled={formik.isSubmitting}> {formik.isSubmitting ? "Please wait..." : "Create Duplicate"}</Button>
                                            </Col>
                                        </Row>

                                    </Form>

                                </div>


                            )}
                        </Formik>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={props.onHide}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
function ShareBotModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Send My Bot</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Formik
                            initialValues={{
                                emailAddress: "",
                                botId: props.masterBotId,
                            }}
                            onSubmit={(values, actions) => {
                                ApiClient.post('bot/share', {
                                    emailAddress: values.emailAddress,
                                    botId: values.botId,
                                }).then((response) => {
                                    actions.setSubmitting(false);
                                    if (response.status == 200) {
                                        values.emailAddress = '';
                                        actions.setStatus('Success!');
                                        setTimeout(() => { props.onHide(); }, 1000);

                                    }

                                }).catch((error) => {
                                    actions.setSubmitting(false);
                                    values.botName = '';
                                    actions.setErrors({ emailAddress: 'Email Address not found or invalid. Please try again later.' })
                                })
                            }}
                        >
                            {formik => (
                                <div>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Form.Group controlId="emailAddress">
                                            <Form.Label>Email Address (email must be registered on Bot-It):</Form.Label>
                                            <Form.Control autoComplete={"off"} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.emailAddress} type="email" placeholder="Email Address" required />
                                            <span className={"text-muted"}>You are sending '{props.mainBotDuplicateName}'</span><br />
                                            <span style={{ color: 'green' }}>{formik.status}</span>
                                            <span style={{ color: 'red' }}>{formik.errors.emailAddress}</span>
                                        </Form.Group>

                                        <Row style={{ textAlign: 'center' }}>
                                            <Col lg={12}>
                                                <Button variant="primary" type="submit" disabled={formik.isSubmitting}> {formik.isSubmitting ? "Please wait..." : "Send"}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={props.onHide}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
function PublicShareBotModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Share My Bot</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Label>Share Url:</Form.Label>
                        <Form.Control autoComplete={"off"} disabled={true} type="email" placeholder="Share URL" value={props.shareUrl} />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                        <Button variant='secondary' onClick={props.onCopyButtonClick}>{props.copyButtonText} </Button>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <h4>Share on Social</h4>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <EmailShareButton subject={'Checkout my bot' + props.botName + 'on Botit.com'} url={props.shareUrl} body={props.shareUrl}>
                            <EmailIcon size={32} round={true} style={{ margin: '10px' }} />
                        </EmailShareButton>
                        <FacebookShareButton quote={'Checkout my bot' + props.botName + 'on Botit.com'} url={props.shareUrl}>
                            <FacebookIcon size={32} round={true} style={{ margin: '10px' }} />
                        </FacebookShareButton>
                        <LinkedinShareButton quote={'Checkout my bot' + props.botName + 'on Botit.com'} url={props.shareUrl}>
                            <LinkedinIcon size={32} round={true} style={{ margin: '10px' }} />
                        </LinkedinShareButton>
                        <PinterestShareButton quote={'Checkout my bot' + props.botName + 'on Botit.com'} url={props.shareUrl}>
                            <PinterestIcon size={32} round={true} style={{ margin: '10px' }} />
                        </PinterestShareButton>
                        <RedditShareButton quote={'Checkout my bot' + props.botName + 'on Botit.com'} url={props.shareUrl}>
                            <RedditIcon size={32} round={true} style={{ margin: '10px' }} />
                        </RedditShareButton>
                        <TelegramShareButton quote={'Checkout my bot' + props.botName + 'on Botit.com'} url={props.shareUrl}>
                            <TelegramIcon size={32} round={true} style={{ margin: '10px' }} />
                        </TelegramShareButton>
                        <TwitterShareButton quote={'Checkout my bot' + props.botName + 'on Botit.com'} url={props.shareUrl}>
                            <TwitterIcon size={32} round={true} style={{ margin: '10px' }} />
                        </TwitterShareButton>
                        <WhatsappShareButton quote={'Checkout my bot' + props.botName + 'on Botit.com'} url={props.shareUrl}>
                            <WhatsappIcon size={32} round={true} style={{ margin: '10px' }} />
                        </WhatsappShareButton>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}
function LoginRequiredModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Login Required</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>This Bot has a step on the website that requires your login information. Please enter your login for the website (Not your Bot-It login) to approve this Bot.</p>
                <Row>
                    <Col xs={12}>
                        <Formik
                            initialValues={{
                                userName: "",
                                password: "",
                            }}
                            onSubmit={(values, actions) => {
                                props.showIsLoading(true);
                                ApiClient.post('bot/recognize/share', {
                                    accepted: true,
                                    id: props.id,
                                    loginTask: { userName: values.userName, password: values.password }
                                }).then((response) => {
                                    actions.setSubmitting(false);
                                    values.userName = '';
                                    values.password = '';
                                    if (response.status == 200) {
                                        props.afterAcceptedComplete(true);
                                        props.showIsLoading(true);
                                        props.onHide();
                                    }

                                }).catch((error) => {
                                    actions.setSubmitting(false);
                                    props.showIsLoading(true);
                                    values.userName = '';
                                    values.password = '';
                                })
                            }}
                        >
                            {formik => (
                                <div>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Form.Group controlId="userName">
                                            <Form.Label>Enter Username or Email
                                            </Form.Label>
                                            <Form.Control autoComplete={"off"} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.userName} type="text" placeholder="Enter Username or Email" required />
                                            <Form.Text className="text-muted">
                                                **Your login information is never shared and is safely secured in our system
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group controlId="password">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control autoComplete={"off"} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password} type={props.showLoginTaskPassword ? "text" : "password"} placeholder="Password" required />
                                            <Form.Group controlId="showExampleCheckbox">
                                                <Form.Check type="checkbox" onChange={props.updateShowPasswordAcceptShareBot} label="Show Password" />
                                            </Form.Group>
                                        </Form.Group>
                                        <Row style={{ textAlign: 'center' }}>
                                            <Col lg={12}>
                                                <Button variant="primary" type="submit" disabled={formik.isSubmitting}> {formik.isSubmitting ? "Please wait..." : "Approve"}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            )}
                        </Formik>
                    </Col>

                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={props.onHide}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
function HowToGuideModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header>
                <Modal.Title>Welcome to Bot-It {props.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        {props.isMobile ? (<Image src={mobileHowTo} fluid rounded />) :
                            (<Image src={desktopHowTo} fluid rounded />)}

                    </Col>
                </Row>
                <Row style={{ marginTop: '40px', marginBottom: '40px' }}>
                    <Col>
                        <h3 style={{ textAlign: 'center' }}>{"Let's Setup your First Bot!"}</h3>
                        {props.isMobile ? (<h5 style={{ textAlign: 'center', marginTop: '10px' }}> {"Click Create My First Bot to get started."}</h5>) :
                            (<div>
                                {<div><h5 style={{ textAlign: 'center', marginTop: '10px' }}>Click <b>Create My First Bot</b> to get started.</h5>
                                </div>}
                            </div>)}

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant="primary" onClick={() => { window.location = "/create" }} block> {"Create My First Bot"}</Button>
                    </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                    <Col>
                        <Button variant="info" onClick={() => { window.location = "/support" }} block> {"Contact Support"}</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}
function UpgradeNeededModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Choose a Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        <h3>{props.message}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <Button onClick={() => { window.location = props.upgradeUrl }}>View Subscriptions</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal >
    );
}
function SubscriptionModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header>
                <Modal.Title>Purchase {props.selectedPlan} Plan <span style={{ color: 'green' }}>{props.selectedPlanPrice}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div id="paymentdetails"></div>
                    </Col>
                </Row>
                <Row>
                    <Button disabled={props.disableCompleteSubscribe} id="submit-button" variant="primary" block>{"Purchase"}</Button>
                </Row>
            </Modal.Body>
        </Modal>
    );
}
function PlusSubscriptionModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" className='subscriptionPlusDialog'>
            <Modal.Body>
                <Row>
                    <Col>
                        <Image src={props.selectedPlan == "Basic" ? standardPlus : props.selectedPlan == "Standard" ? standardPlus : props.selectedPlan == "Pro" ? proPlus : standardPlus} fluid />
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="info" disabled={props.disablePlusModalButtons} onClick={() => props.handleSubscriptionModalShow((props.productIdHolder + "_plus"), (props.selectedPlan + "Plus"))} block>Continue {props.selectedPlan} Plus!</Button>
            </Modal.Footer>
        </Modal>
    );
}
function ThankYouSubscription(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Thank you for your purchase</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <h3 style={{ textDecoration: 'underline', marginBottom: '12px' }}>Purchase Details</h3>
                    </Col>
                </Row>
                <Row className="summarydetails">
                    <Col>
                        <span>✅&nbsp;&nbsp;Account Holder: {props.accountHolder}</span>
                    </Col>
                </Row>
                <Row className="summarydetails">
                    <Col>
                        <span>✅&nbsp;&nbsp;Account Email Address: {props.accountEmailAddress}</span>
                    </Col>
                </Row>
                <Row className="summarydetails">
                    <Col>
                        <span>✅&nbsp;&nbsp;Purchase Date: {moment().format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
                    </Col>
                </Row>
                <Row className="summarydetails">
                    <Col>
                        <span>✅&nbsp;&nbsp;Purchase Item: {props.currentSubscriptionName}</span>
                    </Col>
                </Row>
                <Row className="summarydetails">
                    <Col>
                        <span>✅&nbsp;&nbsp;Subscription State: {props.subscriptionState}</span>
                    </Col>
                </Row>
                <Row className="summarydetails">
                    <Col>
                        <span>✅&nbsp;&nbsp;Next Billing Cycle: {moment(props.subscriptionExpirationPst).format("dddd, MMMM Do YYYY")}</span>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => { window.location = '/' }}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
function LoadingModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Body>
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={7000}
                        />
                    </Col>
                </Row>

            </Modal.Body>
        </Modal >
    );
}
function BotUpgradeModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Upgrade Your Bot 💪</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div>
                            <h4 style={{ textDecoration: 'underline', textAlign: 'center', marginTop: '8px' }}>Add a Cancellation Bot</h4>
                        </div>
                        <div style={{ marginTop: "-10px", textAlign: 'center' }}>
                            <Button onClick={props.clickPopOver} variant='link'>What's a Cancellation Bot?{props.ShowToolTipMessage("A Cancellation Bot is the ultimate booking tool that will run every few minutes to immediately book cancelled reservations for you.", "Book More Reservations?")}</Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        <span>Which Days of the Week Would You Like to Book?</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        <Form.Group controlId="chkSun">
                            <Form.Check type="checkbox" checked=
                                {props.currentCancellationBotDays &&
                                    props.currentCancellationBotDays.includes('SUNDAY')} label="Sun" onChange={props.handleDayPickerOnChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Form.Group controlId="chkMon">
                            <Form.Check checked=
                                {props.currentCancellationBotDays &&
                                    props.currentCancellationBotDays.includes('MONDAY')} type="checkbox" label="Mon" onChange={props.handleDayPickerOnChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Form.Group controlId="chkTues">
                            <Form.Check type="checkbox" checked=
                                {props.currentCancellationBotDays &&
                                    props.currentCancellationBotDays.includes('TUESDAY')} label="Tues" onChange={props.handleDayPickerOnChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Form.Group controlId="chkWed">
                            <Form.Check type="checkbox" checked=
                                {props.currentCancellationBotDays &&
                                    props.currentCancellationBotDays.includes('WEDNESDAY')} label="Wed" onChange={props.handleDayPickerOnChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Form.Group controlId="chkThurs">
                            <Form.Check type="checkbox" checked=
                                {props.currentCancellationBotDays &&
                                    props.currentCancellationBotDays.includes('THURSDAY')} label="Thurs" onChange={props.handleDayPickerOnChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Form.Group controlId="chkFri">
                            <Form.Check type="checkbox" checked=
                                {props.currentCancellationBotDays &&
                                    props.currentCancellationBotDays.includes('FRIDAY')} label="Fri" onChange={props.handleDayPickerOnChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Form.Group controlId="chkSat">
                            <Form.Check type="checkbox" checked=
                                {props.currentCancellationBotDays &&
                                    props.currentCancellationBotDays.includes('SATURDAY')} label="Sat" onChange={props.handleDayPickerOnChange} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 style={{ textDecoration: 'underline', textAlign: 'center', marginTop: '8px', marginBottom: '15px' }}>Increase My Bot Library {props.ShowToolTipMessage("Upgrade your account to expand the amount of Bots you can have in your library. The more Bots you have the more variation you can setup.", "Need more Bots?")}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        <span>How Many Bots Would You Like to Add? </span>
                    </Col>
                </Row>
                <Row style={{ margin: 'auto' }}>
                    <Col xs={3}>
                        <Form.Group controlId="OneBotIncrease">
                            <Form.Check type="checkbox" checked=
                                {props.currentBotsIncreaseAmount &&
                                    props.currentBotsIncreaseAmount.includes('OneBotIncrease')} label="1 Bot" onChange={props.handleBotLibraryIncreaseOnChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={3}>
                        <Form.Group controlId="FiveBotIncrease">
                            <Form.Check type="checkbox" checked=
                                {props.currentBotsIncreaseAmount &&
                                    props.currentBotsIncreaseAmount.includes('FiveBotIncrease')} label="5 Bots" onChange={props.handleBotLibraryIncreaseOnChange} />
                        </Form.Group>
                    </Col>
                    <Col xs={3}>
                        <Form.Group controlId="TenBotIncrease">
                            <Form.Check type="checkbox" checked=
                                {props.currentBotsIncreaseAmount &&
                                    props.currentBotsIncreaseAmount.includes('TenBotIncrease')} label="10 Bots" onChange={props.handleBotLibraryIncreaseOnChange} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <p>${props.cancellationTotalPrice} added per month {props.subscription == "Pro" || props.subscription == "Pro Plus" || props.subscription == "Elite" ? <span style={{ fontSize: "10px" }}>(billed annually)</span> : null}</p>
                <Button variant="primary" disabled={props.disableAddCancellationBot} onClick={props.addNewCancellationBot}>
                    {props.saveAddCancellationButtonText}
                </Button>
                <span style={{ color: 'red' }} hidden={!props.addCancellationBotErrorAlert}>{props.addCancellationBotErrorAlert}</span>
            </Modal.Footer>
        </Modal>
    );
}
function ShowToolTipMessage(message, title) {
    return (
        <OverlayTrigger trigger="click" rootClose placement="left" overlay={<Popover id="popover-basic">
            <Popover.Title as="h3">{title}</Popover.Title>
            <Popover.Content>
                <div dangerouslySetInnerHTML={{ __html: message }} />
            </Popover.Content>
        </Popover>}>
            <img src={infoLight} id='tooltip' width={24} height={24} style={{ marginLeft: "10px" }} />
        </OverlayTrigger>
    )
}

@inject('rootStore')
@observer
export default class UserHome extends Component {
    constructor() {
        super();
        this.state = {
            botCount: 0,
            botsAllowed: 0,
            subscription: '',
            bots: [],
            sharedBots: [],
            currentRunNowBotId: '',
            currentScheduleRunNowBotId: '',
            runNowResult: '',
            confirmBotDeleteModalShow: false,
            confirmDeleteBotName: '',
            confirmDeleteBotIndex: -1,
            confirmDeleteBotId: '',
            confirmPauseBotModalShow: false,
            confirmPauseBotName: '',
            confirmPauseBotId: '',
            liveBotFeedModalShow: false,
            runNowResultColor: 'green',
            connectionColor: { color: 'red' },
            connectionStatus: 'Starting up your Bot...',
            liveFeedMessage: '',
            howToGuideModalShow: false,
            showConfirmDuplicateModal: false,
            isLoading: true,
            name: '',
            email: '',
            oGId: '',
            mainBotDuplicateName: '',
            showUpgradeNeededModal: false,
            upgradeMessage: "You must purchase a subscription to approve or add more Bots to your account.",
            upgradeUrl: "/subscription",
            showLoginTaskPassword: false,
            confirmDenyBotModalShow: false,
            denyBotName: '',
            confirmDenyShareId: '',
            showLoginAcceptSharedBotModal: false,
            acceptShareBotId: '',
            masterBotId: '',
            showSharedBotModal: false,
            mainBotDuplicateName: '',
            publicShareUrl: '',
            publicShareBotName: '',
            publicShareModalShow: false,
            publicShareCopyButtonText: 'Copy',
            referralUrl: '',
            publicReferralCopyButtonText: 'Copy',
            subscriptionUrl: '/subscription',
            subscriptionActionText: '',
            goPlusSubscriptionType: '',
            goPlusSubscriptionId: '',
            showGoPlus: false,
            plusSubscriptionModalShow: false,
            selectedPlan: '',
            isLoadingSpinner: false,
            currentSubscriptionName: '',
            accountHolder: '',
            subscriptionExpirationPst: '',
            currentSubscriptionState: '',
            allowRunNow: false,
            allowScheduleRun: false,
            showAnimation: false,
            finishCreateBotModalShow: false,
            selectedPlanPrice: "",
            templateOptions: [],
            showBusinessMenu: false,
            showBusinessEliteMenu: false,
            bzeTier: '',
            currentCancellationBotDays: null,
            disableAddCancellationBot: true,
            addCancellationBotErrorAlert: null,
            cancellationTotalPrice: 0,
            cancellationBotParent: null,
            botUpgradeModalShow: false,
            saveAddCancellationButtonText: "UPGRADE",
            currentBotsIncreaseAmount: null
        };
    }
    componentDidMount() {
        const rootStore = this.props.rootStore;
        const firstTimeLogin = true;//localStorage.getItem('firstTimeLoginShown');
        this.setupMyBots();
    }
    setupMyBots = () => {
        ApiClient.get('bot/me/all').then((response) => {
            ApiClient.get('bot/me/shared').then((response2) => {
                this.setState({
                    sharedBots: response2.data,
                }, () => {
                    this.setState({
                        bots: response.data,
                        botCount: response.data.length,
                        isLoading: false
                    }, () => {
                        ApiClient.get('user/profile').then((response3) => {
                            if (response3) {
                                var text = "";
                                var goPlus = false;
                                var plusType = "";
                                var plusId = "";
                                if (response3.data.subscriptionName) {
                                    switch (response3.data.subscriptionName) {
                                        case "Starter":
                                            goPlus = false;
                                            if (response3.data.bzE)
                                                text = "Elite Upgrade"
                                            else
                                                text = "Subscription Required❗️"
                                            plusType = "";
                                            plusId = "";
                                            break;
                                        case "Basic":
                                            goPlus = true;
                                            text = "Need Faster Bots? Go Plus!"
                                            plusType = "Standard";
                                            plusId = "com_botit_automation_premium_standard";
                                            break;
                                        case "Basic Plus":
                                            goPlus = true;
                                            text = "Need More Bots? Go Standard Plus!"
                                            plusType = "Standard";
                                            plusId = "com_botit_automation_premium_standard";
                                            break;
                                        case "Standard":
                                            goPlus = true;
                                            text = "Need Faster Bots? Go Plus!"
                                            plusType = "Standard";
                                            plusId = "com_botit_automation_premium_standard";
                                            break;
                                        case "Standard Plus":
                                            goPlus = false;
                                            text = ""
                                            plusType = "";
                                            plusId = "";
                                            break;
                                        case "Pro":
                                            goPlus = true;
                                            text = "Need Faster Bots? Go Plus!"
                                            plusType = "Pro";
                                            plusId = "com_botit_automation_premium_pro";
                                            break;
                                        case "Pro Plus":
                                            goPlus = false;
                                            text = ""
                                            plusType = "";
                                            plusId = "";
                                            break;
                                        case "Elite":
                                            goPlus = false;
                                            text = ""
                                            plusType = "";
                                            plusId = "";
                                            break;
                                    }
                                }
                                this.setState(prev => ({
                                    showGoPlus: goPlus, subscriptionActionText: text, goPlusSubscriptionType: plusType, goPlusSubscriptionId: plusId,
                                    howToGuideModalShow: prev.sharedBots == 0 && prev.bots == 0 ? true : false, subscription: response3.data.subscriptionName, name: response3.data.name,
                                    email: response3.data.emailAddress, referralUrl: response3.data.referralUrl, currentSubscriptionName: response3.data.subscriptionName,
                                    allowRunNow: response3.data.allowBZEBotRunNow, allowScheduleRun: response3.data.allowBZEBotScheduleRun, botsAllowed: response3.data.botsAllowed,
                                    showBusinessEliteMenu: response3.data.bzE, bzeTier: response3.data.bzeTier
                                }))
                            }
                            ApiClient.get('bot/v2/support/package').then((supportResponse) => {
                                this.setState({ templateOptions: supportResponse.data.templateOptions })
                            }).catch((error) => { });
                        }).catch((error) => {
                        });
                    });
                });
            }).catch((error) => {
                alert(error.response.error)
            });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    runBotNow = (id, which, runCounter) => () => {
        this.setState({
            currentRunNowBotId: id,
        },
            () => {
                ApiClient.post('bot/that/now/' + id).then((response) => {
                    if (response) {
                        this.setState({
                            runNowResult: response.data.message,
                            runNowResultColor: 'green',
                            liveBotFeedModalShow: true, connectionColor: { color: 'red' }, liveFeedMessage: '', connectionStatus: 'Starting up your Bot...',
                            bots:
                                update(this.state.bots, { [which]: { counter: { $set: runCounter + 1 } } })

                        }, () => {
                            const newConnection = new HubConnectionBuilder()
                                .withUrl(config.api + 'run/stats?botId=' + id)
                                .withAutomaticReconnect()
                                .build();
                            newConnection.start()
                                .then(result => {
                                    console.log('Connected!');

                                    newConnection.on('ReceiveMessage', message => {
                                        switch (message.hubRunMessageType) {
                                            case "RUNSTARTED":
                                                this.setState({ connectionColor: { color: 'green' }, connectionStatus: "Your Bot is running!", liveFeedMessage: message.message })
                                                break;
                                            case "RUNSTEP":
                                                this.setState(prevState => ({ connectionColor: { color: 'green' }, connectionStatus: "Your Bot is running!", liveFeedMessage: prevState.liveFeedMessage.concat("\n \u2022" + message.message) }));
                                                break;
                                            case "RUNCOMPLETE":
                                            case "RUNFAILED":
                                                this.setState({ connectionColor: { color: 'black' }, liveFeedMessage: message.message, connectionStatus: "Finished" })
                                                newConnection.stop();
                                                break;
                                        }
                                    });
                                })
                                .catch(e => console.log('Connection failed: ', e));
                            setTimeout(() => {
                                this.setState({ currentRunNowBotId: '', runNowResult: '' });
                            }, 3000);
                        });


                    }
                }).catch((error) => {
                    this.setState({
                        runNowResult: error.response.data,
                        runNowResultColor: 'red'
                    }, () => {
                        setTimeout(() => {
                            this.setState({ currentRunNowBotId: '', runNowResult: '' });
                        }, 3000);
                    });

                });
            })
    }
    handleDayPickerOnChange = (e) => {
        var currentValue = this.state.currentCancellationBotDays;
        if (currentValue == null || currentValue == "null") currentValue = "";
        switch (e.target.getAttribute("id")) {
            case 'chkSun':
                this.updateBotRunDaysForCancellationBot(currentValue, e.target.checked, 'SUNDAY,', /SUNDAY,/gi);
                break;
            case 'chkMon':
                this.updateBotRunDaysForCancellationBot(currentValue, e.target.checked, 'MONDAY,', /MONDAY,/gi);
                break;
            case 'chkTues':
                this.updateBotRunDaysForCancellationBot(currentValue, e.target.checked, 'TUESDAY,', /TUESDAY,/gi);
                break;
            case 'chkWed':
                this.updateBotRunDaysForCancellationBot(currentValue, e.target.checked, 'WEDNESDAY,', /WEDNESDAY,/gi);
                break;
            case 'chkThurs':
                this.updateBotRunDaysForCancellationBot(currentValue, e.target.checked, 'THURSDAY,', /THURSDAY,/gi);
                break;
            case 'chkFri':
                this.updateBotRunDaysForCancellationBot(currentValue, e.target.checked, 'FRIDAY,', /FRIDAY,/gi);
                break;
            case 'chkSat':
                this.updateBotRunDaysForCancellationBot(currentValue, e.target.checked, 'SATURDAY,', /SATURDAY,/gi);
                break;
        }
    }
    handleBotLibraryIncreaseOnChange = (e) => {
        var currentValue = this.state.currentBotsIncreaseAmount;
        if (currentValue == null || currentValue == "null") currentValue = "";
        switch (e.target.getAttribute("id")) {
            case 'OneBotIncrease':
                this.updateBotLibraryCount(currentValue, e.target.checked, 'OneBotIncrease,', /OneBotIncrease,/gi, () => {
                    if (this.state.currentBotsIncreaseAmount.includes("FiveBotIncrease")) {
                        this.setState(prevStateRemove => ({ currentBotsIncreaseAmount: prevStateRemove.currentBotsIncreaseAmount.replace(/FiveBotIncrease,/gi, ''), cancellationTotalPrice: (prevStateRemove.cancellationTotalPrice - 10) }));
                    }
                    if (this.state.currentBotsIncreaseAmount.includes("TenBotIncrease")) {
                        this.setState(prevStateRemove => ({ currentBotsIncreaseAmount: prevStateRemove.currentBotsIncreaseAmount.replace(/TenBotIncrease,/gi, ''), cancellationTotalPrice: (prevStateRemove.cancellationTotalPrice - 20) }));
                    }

                });

                break;
            case 'FiveBotIncrease':
                this.updateBotLibraryCount(currentValue, e.target.checked, 'FiveBotIncrease,', /FiveBotIncrease,/gi, () => {
                    if (this.state.currentBotsIncreaseAmount.includes("OneBotIncrease")) {
                        this.setState(prevStateRemove => ({ currentBotsIncreaseAmount: prevStateRemove.currentBotsIncreaseAmount.replace(/OneBotIncrease,/gi, ''), cancellationTotalPrice: (prevStateRemove.cancellationTotalPrice - 5) }));
                    }
                    if (this.state.currentBotsIncreaseAmount.includes("TenBotIncrease")) {
                        this.setState(prevStateRemove => ({ currentBotsIncreaseAmount: prevStateRemove.currentBotsIncreaseAmount.replace(/TenBotIncrease,/gi, ''), cancellationTotalPrice: (prevStateRemove.cancellationTotalPrice - 20) }));
                    }
                });

                break;
            case 'TenBotIncrease':
                this.updateBotLibraryCount(currentValue, e.target.checked, 'TenBotIncrease,', /TenBotIncrease,/gi, () => {
                    if (this.state.currentBotsIncreaseAmount.includes("OneBotIncrease")) {
                        this.setState(prevStateRemove => ({ currentBotsIncreaseAmount: prevStateRemove.currentBotsIncreaseAmount.replace(/OneBotIncrease,/gi, ''), cancellationTotalPrice: (prevStateRemove.cancellationTotalPrice - 5) }));
                    }
                    if (this.state.currentBotsIncreaseAmount.includes("FiveBotIncrease")) {
                        this.setState(prevStateRemove => ({ currentBotsIncreaseAmount: prevStateRemove.currentBotsIncreaseAmount.replace(/FiveBotIncrease,/gi, ''), cancellationTotalPrice: (prevStateRemove.cancellationTotalPrice - 10) }));
                    }
                });

                break;
        }
    }
    clickPopOver = () => {
        document.getElementById("tooltip").click();
    }
    updateBotRunDaysForCancellationBot = (currentValue, isChecked, whichDay, replaceEx) => {
        if (isChecked) {
            this.setState(prevState => ({ currentCancellationBotDays: currentValue += whichDay, cancellationTotalPrice: prevState.cancellationTotalPrice + 15 }), () => this.validateCancellationBotDays());
        }
        else {
            this.setState(prevState => ({ currentCancellationBotDays: currentValue.replace(replaceEx, ''), cancellationTotalPrice: prevState.cancellationTotalPrice - 15 }), () => this.validateCancellationBotDays());
        }
    }
    updateBotLibraryCount = (currentValue, isChecked, whichAmount, replaceEx, postAction) => {
        if (isChecked) {
            this.setState(prevState => ({ currentBotsIncreaseAmount: currentValue += whichAmount, cancellationTotalPrice: prevState.cancellationTotalPrice + (whichAmount.includes("OneBotIncrease") ? 5 : whichAmount.includes("FiveBotIncrease") ? 10 : whichAmount.includes("TenBotIncrease") ? 20 : 0) }), () => {
                this.validateCancellationBotDays();
                postAction();
            });
        }
        else {
            this.setState(prevState => ({ currentBotsIncreaseAmount: currentValue.replace(replaceEx, ''), cancellationTotalPrice: prevState.cancellationTotalPrice - (whichAmount.includes("OneBotIncrease") ? 5 : whichAmount.includes("FiveBotIncrease") ? 10 : whichAmount.includes("TenBotIncrease") ? 20 : 0) }), () => {
                this.validateCancellationBotDays();
            });
        }
    }
    validateCancellationBotDays = () => {
        if (this.state.currentCancellationBotDays || this.state.currentBotsIncreaseAmount) {
            this.setState({ disableAddCancellationBot: false });
        }
        else {
            this.setState({ disableAddCancellationBot: true });
        }
    }
    addNewCancellationBot = () => {
        this.setState(prevState => ({
            currentBotsIncreaseAmount: this.state.currentBotsIncreaseAmount && this.state.currentBotsIncreaseAmount.charAt(this.state.currentBotsIncreaseAmount.length - 1) === ',' ? prevState.currentBotsIncreaseAmount.slice(0, -1) : prevState.currentBotsIncreaseAmount,
            currentCancellationBotDays: this.state.currentCancellationBotDays && this.state.currentCancellationBotDays.charAt(this.state.currentCancellationBotDays.length - 1) === ',' ? prevState.currentCancellationBotDays.slice(0, -1) : prevState.currentCancellationBotDays,
            disableAddCancellationBot: true, saveAddCancellationButtonText: "Upgrading..."
        }), () => {
            ApiClient.post('bot/create/upgrade', { oGId: this.state.cancellationBotParent, bookingDays: this.state.currentCancellationBotDays, botLibraryIncreaseAmount: this.state.currentBotsIncreaseAmount }).then((response) => {
                if (response) {
                    this.setState({
                        cancellationBotParent: null, saveAddCancellationButtonText: "Save", currentCancellationBotDays: null, disableAddCancellationBot: true, cancellationTotalPrice: 0, addCancellationBotErrorAlert: null, currentBotsIncreaseAmount: null
                    }, () => {
                        this.setupMyBots();
                        this.handleBotUpgradeModalShow(false);
                    });
                }
            }).catch((error) => {
                this.setState({
                    addCancellationBotErrorAlert: error.response.data, saveAddCancellationButtonText: "Save", disableAddCancellationBot: false
                });
            });
        });
    }
    handleBotUpgradeModalShow = (show, id) => {
        if (!show)
            this.setState({ botUpgradeModalShow: show, cancellationBotParent: null, currentCancellationBotDays: null, disableAddCancellationBot: true, cancellationTotalPrice: 0, addCancellationBotErrorAlert: null, currentBotsIncreaseAmount: null })
        else
            this.setState({ botUpgradeModalShow: show, cancellationBotParent: id })
    }
    scheduleRunNowBot = (id, which, runCounter) => () => {
        this.setState({
            currentScheduleRunNowBotId: id,
        },
            () => {
                ApiClient.post('bot/that/local/now/' + id).then((response) => {
                    if (response) {
                        this.setState({
                            runNowResult: response.data.message,
                            runNowResultColor: 'green',
                            liveBotFeedModalShow: true, connectionColor: { color: 'red' }, liveFeedMessage: '', connectionStatus: 'Your Bot is scheduled to run',
                            bots:
                                update(this.state.bots, { [which]: { counter: { $set: runCounter + 1 } } })
                        });
                    }
                }).catch((error) => {
                    this.setState({
                        runNowResult: error.response.data,
                        runNowResultColor: 'red'
                    }, () => {
                        setTimeout(() => {
                            this.setState({ currentScheduleRunNowBotId: '', runNowResult: '' });
                        }, 3000);
                    });

                });
            })
    }
    startInfiniteBots = (id, which, runCounter) => () => {
        this.setState({
            currentRunNowBotId: id,
        },
            () => {
                ApiClient.post('bot/that/infinite/all').then((response) => {
                    if (response) {
                        this.setState({
                            runNowResult: response.data.message,
                            runNowResultColor: 'green',
                            liveBotFeedModalShow: true, connectionColor: { color: 'red' }, liveFeedMessage: '', connectionStatus: 'Starting up your Bot...',
                            bots:
                                update(this.state.bots, { [which]: { counter: { $set: runCounter + 1 } } })

                        }, () => {
                            const newConnection = new HubConnectionBuilder()
                                .withUrl(config.api + 'run/stats?botId=' + id)
                                .withAutomaticReconnect()
                                .build();
                            newConnection.start()
                                .then(result => {
                                    console.log('Connected!');

                                    newConnection.on('ReceiveMessage', message => {
                                        switch (message.hubRunMessageType) {
                                            case "RUNSTARTED":
                                                this.setState({ connectionColor: { color: 'green' }, connectionStatus: "Your Bot is running!", liveFeedMessage: message.message })
                                                break;
                                            case "RUNSTEP":
                                                this.setState(prevState => ({ connectionColor: { color: 'green' }, connectionStatus: "Your Bot is running!", liveFeedMessage: prevState.liveFeedMessage.concat("\n \u2022" + message.message) }));
                                                break;
                                            case "RUNCOMPLETE":
                                            case "RUNFAILED":
                                                this.setState({ connectionColor: { color: 'black' }, liveFeedMessage: message.message, connectionStatus: "Finished" })
                                                newConnection.stop();
                                                break;
                                        }
                                    });
                                })
                                .catch(e => console.log('Connection failed: ', e));
                            setTimeout(() => {
                                this.setState({ currentRunNowBotId: '', runNowResult: '' });
                            }, 3000);
                        });


                    }
                }).catch((error) => {
                    this.setState({
                        runNowResult: error.response.data,
                        runNowResultColor: 'red'
                    }, () => {
                        setTimeout(() => {
                            this.setState({ currentRunNowBotId: '', runNowResult: '' });
                        }, 3000);
                    });

                });
            })
    }
    onShowConfirmBotDeletedModal = (show, botName, id, index) => {
        if (show) {
            this.setState({ confirmBotDeleteModalShow: show, confirmDeleteBotName: botName, confirmDeleteBotId: id, confirmDeleteBotIndex: index })
        } else {
            this.setState({ confirmBotDeleteModalShow: show })
        }
    }
    showPlusModal = () => {
        this.setState({ plusSubscriptionModalShow: true, selectedPlan: this.state.goPlusSubscriptionType, productIdHolder: this.state.goPlusSubscriptionId });
    }
    getButtonText = () => {
        if (this.state.currentSubscriptionName === 'Basic' || this.state.currentSubscriptionName === 'Basic Plus') this.setState({ completeButtonText: 'Upgrade Plan' });
        else if (this.state.currentSubscriptionName === 'Starter') this.setState({ completeButtonText: 'Upgrade Plan' });
        else if (this.state.currentSubscriptionName === 'Standard' || this.state.currentSubscriptionName === 'Standard Plus') {
            if (this.state.selectedPlan == 'Basic') this.setState({ completeButtonText: 'Downgrade Plan' });
            else if (this.state.selectedPlan == 'Pro' || this.state.selectedPlan == 'Pro Plus' || this.state.selectedPlan == 'Standard' || this.state.selectedPlan == 'Standard Plus') this.setState({ completeButtonText: 'Upgrade Plan' });
        }
        else {
            if (this.state.selectedPlan == 'Basic' || this.state.selectedPlan == 'Basic Plus' || this.state.selectedPlan == 'Standard' || this.state.selectedPlan == 'Standard Plus') this.setState({ completeButtonText: 'Downgrade Plan' });
            else this.setState({ completeButtonText: 'Upgrade Plan' });
        }
    }
    onShowPublicShareModal = (show) => {
        this.setState({ publicShareModalShow: show })
    }
    onCopyButtonClick = () => {
        navigator.clipboard.writeText(this.state.publicShareUrl)
        this.setState({ publicShareCopyButtonText: 'Copied!' }, () => {
            setTimeout(
                () => this.setState({ publicShareCopyButtonText: 'Copy' }),
                3000
            );
        });
    }
    onCopyReferralButtonClick = () => {
        navigator.clipboard.writeText(this.state.referralUrl)
        this.setState({ publicReferralCopyButtonText: 'Copied!' }, () => {
            setTimeout(
                () => this.setState({ publicReferralCopyButtonText: 'Copy' }),
                3000
            );
        });
    }
    onShowConfirmBotDenyModal = (show, botName, id) => {
        if (show) {
            this.setState({ confirmDenyBotModalShow: show, denyBotName: botName, acceptShareBotId: id })
        } else {
            this.setState({ confirmDenyBotModalShow: show })
        }
    }
    onShowConfirmPauseBotModal = (show, botName, id) => {
        if (show) {
            this.setState({ confirmPauseBotModalShow: show, confirmPauseBotName: botName, confirmPauseBotId: id })
        } else {
            this.setState({ confirmPauseBotModalShow: show })
        }
    }
    updateBotDataFromDuplicate = (data, count) => {
        this.setState({ bots: data, botCount: count })
    }
    onShowLiveFeeddModal = (show) => {
        if (show) {
            this.setState({ liveBotFeedModalShow: show, connectionColor: { color: 'red' }, liveFeedMessage: '' })
        } else {
            this.setState({ liveBotFeedModalShow: show })
        }

    }
    onShowConfirmDuplicateModal = (show) => {
        this.setState({ showConfirmDuplicateModal: show })
    }
    createDuplicate = (id, name) => {
        ApiClient.get('user/maxed/bot/creations').then((response) => {
            if (response.data == true) {
                this.setState({ showUpgradeNeededModal: true })
            }
            else {
                this.setState({ oGId: id, mainBotDuplicateName: name })
                this.onShowConfirmDuplicateModal(true);
            }

        }).catch((error) => {
            alert(error.response.error)
        });
    }
    deleteBot = () => {
        ApiClient.post('bot/delete/' + this.state.confirmDeleteBotId).then((response) => {
            var bots = this.state.bots;
            var botCount = this.state.botCount;
            bots.splice(this.state.confirmDeleteBotIndex, 1);
            this.setState(prev => ({
                confirmBotDeleteModalShow: false, bots, botCount: botCount - 1,
                howToGuideModalShow: prev.sharedBots == 0 && bots == 0 ? true : false,
            }));
        }).catch((error) => {
            this.setState({ confirmBotDeleteModalShow: false }, () => { alert(error.response.data) });
        });
    }
    onShowLoginAcceptSharedBotModal = (show) => {
        this.setState({ showLoginAcceptSharedBotModal: show })
    }
    updateShowPasswordAcceptShareBot = () => {
        if (this.state.showLoginTaskPassword) {
            this.setState({ showLoginTaskPassword: false })
        }
        else {
            this.setState({ showLoginTaskPassword: true })
        }

    }
    onShareBotMenuClick = (botId, botName) => {
        this.setState({ mainBotDuplicateName: botName, masterBotId: botId }, () => {
            this.onShowShareBotModal(true);
        });
    }
    onPublicShareBotMenuClick = (botId, botName) => {
        ApiClient.get('bot/share/url/' + botId).then((response) => {
            this.setState({ publicShareUrl: response.data.url, publicShareBotName: botName }, () => {
                this.onShowPublicShareModal(true);
            });
        }).catch((error) => {
        });
    }
    onShowShareBotModal = (show) => {
        this.setState({ showSharedBotModal: show })
    }

    acceptBot = (id, minimumSubscription, requireLoginDetails) => {
        ApiClient.get('user/maxed/bot/creations').then((response) => {
            if (response.data == true) {
                this.setState({ showUpgradeNeededModal: true })
            }
            else {
                if (this.state.subscription == "Starter") {
                    this.setState({ showUpgradeNeededModal: true })
                    return;
                }
                else if (this.state.subscription == "Basic" || this.state.subscription == "Basic PLUS") {
                    this.setState({ showUpgradeNeededModal: true })
                    return;
                }
                else if (minimumSubscription == "PRO" || minimumSubscription == "Elite") {
                    if (this.state.subscription == "Standard" || this.state.subscription == "STANDARD PLUS") {
                        this.setState({ showUpgradeNeededModal: true })
                        return;
                    }
                }
                else if (minimumSubscription == "Elite") {
                    if (this.state.subscription == "Pro" || this.state.subscription == "Pro Plus") {

                        this.setState({ showUpgradeNeededModal: true })
                        return;
                    }
                }
                else if (minimumSubscription == "STANDARDPLUS" || minimumSubscription == "PROPLUS") {
                    if (this.state.subscription == "Standard" || this.state.subscription == "Pro") {
                        this.setState({
                            showUpgradeNeededModal: true, upgradeMessage: "Congratulations, this is a one of our premium tee time bots that comes with extra bot power to get you bookings! In order to run your new Bot, you will need a premium subscription.",
                            upgradeUrl: "/subscription?pre=true"
                        })
                        return;
                    }
                }
                this.setState({ acceptShareBotId: id })
                if (requireLoginDetails) {
                    this.onShowLoginAcceptSharedBotModal(true);
                }
                else {
                    this.showIsLoading(true);
                    ApiClient.post('bot/recognize/share', {
                        accepted: true,
                        id: id
                    }).then((response) => {
                        if (response.status == 200) {
                            this.afterAcceptedComplete(true);
                        }

                    }).catch((error) => {
                        this.showIsLoading(false);
                    })
                }

            }
        }).catch((error) => {
            this.showIsLoading(false);
            alert(error.response.error)
        });
    }
    denyBot = () => {
        ApiClient.post('bot/recognize/share', {
            accepted: false,
            id: this.state.acceptShareBotId
        }).then((response) => {
            this.onShowConfirmBotDenyModal(false)
            this.afterAcceptedComplete();
        }).catch((error) => {
            alert(error.response.error);
        })
    }
    afterAcceptedComplete = (showFireworkFinish) => {
        ApiClient.get('bot/me/all').then((response) => {
            ApiClient.get('bot/me/shared').then((response2) => {
                this.setState({
                    sharedBots: response2.data,
                }, () => {
                    this.showIsLoading(false);
                    this.setState({
                        bots: response.data,
                        botCount: response.data.length,
                        isLoading: false, howToGuideModalShow: response2.data == 0 && response.data == 0 ? true : false,
                    }, () => { if (showFireworkFinish) this.handleFinishModalShow(true) });
                });
            }).catch((error) => {
                alert(error.response.error)
            });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    pauseBot = () => {
        this.setState({ isLoading: true, bots: [] }, () => {
            ApiClient.post('bot/pause/' + this.state.confirmPauseBotId).then((response) => {
                ApiClient.get('bot/me/all').then((response) => {
                    this.setState({
                        bots: response.data,
                        botCount: response.data.length,
                        isLoading: false
                    }, () => { this.setState({ confirmPauseBotModalShow: false }); });
                }).catch((error) => {
                    alert(error.response.error)
                });

            }).catch((error) => {
            });
        });
    }
    unPauseBot = (id) => {
        this.setState({ isLoading: true, bots: [] }, () => {
            ApiClient.post('bot/unpause/' + id).then((response) => {
                ApiClient.get('bot/me/all').then((response) => {
                    this.setState({
                        bots: response.data,
                        botCount: response.data.length,
                        isLoading: false
                    });
                }).catch((error) => {
                    alert(error.response.error)
                });

            }).catch((error) => {
            });
        })

    }
    onShowHowToGuideModal = (show, createBot) => {
        if (!show) localStorage.setItem('firstTimeLoginShown', "true");
        this.setState({ howToGuideModalShow: show }, () => { if (createBot) window.location = "/create"; })
    }
    getButtonActiveText = (active, paused) => {
        if (active) return paused ? "Paused" : "Active & Scheduled to Automatically Run"
        else return "Pending Setup"
    }
    handleSubscriptionModalShow = (productId, displayName) => {
        this.setState({ disablePlusModalButtons: true }, () => {
            this.showIsLoading(true);
            if (this.isDowngradeSubscription(displayName)) {
                ApiClient.get('subscription/prevent/downgrade/' + displayName + '/' + this.state.currentSubscriptionName).then((response) => {
                    if (response && response.data) {
                        this.setState({ plusSubscriptionModalShow: false, downgradeSubscriptionErrorShow: true, disablePlusModalButtons: false })
                        return;
                    }
                    this.brainTreeSetup(productId, displayName);
                }).catch((error) => {
                    this.setState({ disablePlusModalButtons: false })
                });
            } else this.brainTreeSetup(productId, displayName);
        })
    }
    brainTreeSetup = (productId, displayName) => {
        ApiClient.get('subscription/braintree/client/token').then((response) => {
            this.showIsLoading(false);
            if (response && response.status == 200) {
                var price = "";
                switch (displayName) {
                    case "Basic":
                        price = "$3.99"
                        break;
                    case "BasicPlus":
                        price = "$7.99"
                        break;
                    case "Standard":
                        price = "$9.99"
                        break;
                    case "StandardPlus":
                        price = "$25.00"
                        break;
                    case "Pro":
                        price = "$99.99"
                        break;
                    case "ProPlus":
                        price = "$275.00"
                        break;
                }
                this.setState({ disablePlusModalButtons: false, plusSubscriptionModalShow: false, subscriptionModalShow: true, selectedPlan: displayName, selectedPlanPrice: price }, () => { this.getButtonText(); this.handleThankYouModalShow(false); })
                var button = document.querySelector('#submit-button');
                dropin.create({
                    authorization: response.data,
                    container: '#paymentdetails'
                }, (createErr, instance) => {
                    button.addEventListener('click', () => {
                        instance.requestPaymentMethod((requestPaymentMethodErr, payload) => {
                            ApiClient.post('subscription/braintree/create',
                                {
                                    payment_method_nonce: payload.nonce,
                                    product_id: productId
                                }).then((response) => {
                                    if (response && response.status == 200) {
                                        if (this.state.selectedPlanPrice && this.state.selectedPlanPrice.startsWith("$"))
                                            TiktokPixel.track('CompletePayment', { value: Number(this.state.selectedPlanPrice.replace(/[^0-9.-]+/g, "")), content_type: "purchase_" + this.state.selectedPlan, currency: 'USD' });
                                        this.getSubscription();
                                        this.setState({ subscriptionModalShow: false }, () => this.setState({ thankYouSubscriptionModalShow: true }))
                                    }
                                }).catch((error) => {
                                    alert(error)
                                });
                        });
                    });
                });
            }
        }).catch((error) => {
            this.showIsLoading(false);
            this.setState({ disablePlusModalButtons: false })
            alert(error)
        });
    }
    showIsLoading = (show) => {
        this.setState({ isLoadingSpinner: show });
    }
    isDowngradeSubscription = (displayName) => {
        if (this.state.currentSubscriptionName === 'Standard' || this.state.currentSubscriptionName === 'StandardPlus') {
            if (displayName == 'Basic' || displayName == 'BasicPlus') return true
        }
        else if (this.state.currentSubscriptionName === 'Pro' || this.state.currentSubscriptionName === 'ProPlus') {
            if (displayName == 'Basic' || displayName == 'BasicPlus' || displayName == 'Standard' || displayName == 'StandardPlus') return true;
        }
        else if (this.state.currentSubscriptionName === 'Elite') {
            if (displayName == 'Basic' || displayName == 'BasicPlus' || displayName == 'Standard' || displayName == 'StandardPlus' || displayName == 'Pro' || displayName == 'ProPlus') return true;
        }
        return false;
    }
    handleThankYouModalShow = (show) => {
        this.setState({ thankYouSubscriptionModalShow: show })
    }
    getSubscription = () => {
        ApiClient.get('user/profile').then((response) => {
            if (response) {
                var text = "";
                var goPlus = false;
                var plusType = "";
                var plusId = "";
                if (response.data.subscriptionName) {
                    switch (response.data.subscriptionName) {
                        case "Starter":
                            goPlus = false;
                            text = "Subscription Required❗️"
                            plusType = "";
                            plusId = "";
                            break;
                        case "Basic":
                            goPlus = true;
                            text = "Need Faster Bots? Go Plus!"
                            plusType = "Standard";
                            plusId = "com_botit_automation_premium_standard";
                            break;
                        case "Basic Plus":
                            goPlus = true;
                            text = "Need More Bots? Go Standard Plus!"
                            plusType = "Standard";
                            plusId = "com_botit_automation_premium_standard";
                            break;
                        case "Standard":
                            goPlus = true;
                            text = "Need Faster Bots? Go Plus!"
                            plusType = "Standard";
                            plusId = "com_botit_automation_premium_standard";
                            break;
                        case "Standard Plus":
                            goPlus = false;
                            text = ""
                            plusType = "";
                            plusId = "";
                            break;
                        case "Pro":
                            goPlus = true;
                            text = "Need Faster Bots? Go Plus!"
                            plusType = "Pro";
                            plusId = "com_botit_automation_premium_pro";
                            break;
                        case "Pro Plus":
                            goPlus = false;
                            text = ""
                            plusType = "";
                            plusId = "";
                            break;
                        case "Elite":
                            goPlus = false;
                            text = ""
                            plusType = "";
                            plusId = "";
                            break;
                    }
                }
                this.setState({
                    showGoPlus: goPlus, subscriptionActionText: text, goPlusSubscriptionType: plusType, goPlusSubscriptionId: plusId,
                    subscription: response.data.subscriptionName, name: response.data.name, email: response.data.emailAddress,
                    referralUrl: response.data.referralUrl, currentSubscriptionName: response.data.subscriptionName,
                    currentSubscriptionState: response.data.subscriptionState, accountHolder: response.data.name,
                    subscriptionExpirationPst: response.data.subscriptionExpirationPst
                })
            }
        }).catch((error) => {
        });
    }
    handleFinishModalShow = (show) => {
        this.setState({ finishCreateBotModalShow: show })
    }
    render() {

        const rootStore = this.props.rootStore;

        return (
            <Container fluid>
                <Row>
                    <Col lg={6}>
                        <h1 style={{ fontWeight: 'bold' }}>My Bots</h1>
                    </Col>
                    <Col lg={6} className="profileInfo">
                        <span style={{ fontWeight: 'bold' }}>{this.state.name}</span><br />
                        <span style={{ fontWeight: 'bold' }}>{this.state.email}</span><br />
                        <a hidden={!this.state.showBusinessEliteMenu} href='/business'>My Business Settings</a><br />
                    </Col>

                </Row>
                <div className='col-sm-3 col-sm-offset-3' style={{ margin: '0 auto', textAlign: 'center' }}>
                    <Form.Label style={{ fontWeight: 'bold' }} htmlFor="basic-url">Refer a Friend</Form.Label>
                    <InputGroup className="mb-3 d-flex justify-content-center">
                        <Button variant="success" id="button-referral-link" onClick={this.onCopyReferralButtonClick}>
                            {this.state.publicReferralCopyButtonText}
                        </Button>
                        <Form.Control
                            aria-label="My Referral Link"
                            disabled={true}
                            size={'sm'}
                            aria-describedby="basic-referral-link"
                            value={this.state.referralUrl}
                        />
                    </InputGroup>
                </div>
                <Row style={{ marginTop: "20px" }}>
                    <Col xs={6}>
                        <Row >
                            <Col xs={10}>
                                <span className="text-muted titles">Bots</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span className="titleDescription">{this.state.botCount}/{this.state.botsAllowed}</span>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="subscriptionCol" xs={6} >
                        <Row>
                            <Col className="d-flex justify-content-end">
                                <span className="text-muted titles">{!this.state.showBusinessEliteMenu ? "Subscription" : "Business Elite Tier"}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-end">
                                <span className="titleDescription">{!this.state.showBusinessEliteMenu ? this.state.subscription : this.state.bzeTier}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-end">
                                <Button onClick={() => { this.state.showGoPlus ? this.showPlusModal() : this.state.subscriptionActionText ? window.location = this.state.subscriptionUrl : void (0) }}
                                    className="btnRunNow" style={{ fontSize: '16px', color: 'rgb(255 119 0)' }} variant="link">{this.state.subscriptionActionText}
                                    {this.state.showGoPlus && <img width={16} height={16} src={star} style={{ marginBottom: '4px', marginLeft: '2px' }} />}</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {
                    this.state.bots.length == 0 && this.state.sharedBots.length == 0 ? (
                        <div style={{ marginTop: '20px' }}>
                            {!this.state.isLoading ? (
                                <div>
                                    <Row>
                                        <Col className="nobots" style={{ textAlign: 'center' }} lg={12}>
                                            <h1>What would you like to automate today?</h1>
                                        </Col>
                                    </Row>
                                    <Templates templateOptions={this.state.templateOptions} hideTemplatePicker={this.state.bots.length != 0} startSeries={false} goToCreate={true}></Templates>
                                </div>

                            ) : (
                                <div>
                                    <Row>
                                        <Col className="nobots" style={{ textAlign: 'center' }} lg={12}>
                                            Loading Bots...
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ textAlign: 'center' }} lg={12}>
                                            <img width={200} height={150} src={lightInverseLogo} />
                                        </Col>
                                    </Row>
                                </div>

                            )}
                        </div>
                    ) : (<div>
                        <Row>
                            <Col style={{ textAlign: 'center' }}>
                                <Button class="btn btn-primary" style={{ fontSize: '30px' }} href="/create" >Create New Bot</Button>
                            </Col>
                        </Row>
                        {this.state.sharedBots.map((bot, index) =>
                            <Row className="botItem justify-content-lg-center">
                                <Col className="botCol" lg={10}>
                                    <Row>
                                        <Col className="botItemTitle">
                                            {bot.botName}<span style={{ fontSize: '16px' }}>{(" (" + bot.minimumSubscriptionPlan + " required)")}</span></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="text-muted">From</span>
                                        </Col>
                                        <Col className="botItemCounterTitle">
                                            <span className="text-muted ">Date</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="botItemTime">
                                                {"Bot-It Team"}
                                            </span>
                                        </Col>
                                        <Col style={{ textAlign: 'right' }}>
                                            <span className="botItemCounterData">{bot.dateCreated}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button size="lg" onClick={() => this.acceptBot(bot.postId, bot.minimumSubscriptionPlan, bot.requireLoginDetails)} variant="success" block>Approve</Button>
                                            <Button size="lg" onClick={() => this.onShowConfirmBotDenyModal(true, bot.botName, bot.postId,)} style={{ marginBottom: '15px' }} variant="danger" block>Deny</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                        {this.state.bots.map((bot, index) =>
                            <Row className="botItem justify-content-lg-center">
                                <Col className="botCol" lg={10}>
                                    <Row>
                                        <Col className="botItemTitle">
                                            {bot.name}</Col>
                                        <Col>
                                            <NavDropdown style={{ float: 'Right' }} title="Manage" id="nav-dropdown">
                                                {<NavDropdown.Item onClick={() => { window.location = "/create?bid=" + bot.id }} eventKey="4.1">Edit</NavDropdown.Item>}
                                                {<NavDropdown.Item onSelect={() => this.handleBotUpgradeModalShow(true, bot.id)} eventKey="4.2">Add Power <img width={16} height={16} src={star} style={{ marginBottom: '4px', marginLeft: '2px' }} /></NavDropdown.Item>}
                                                {<NavDropdown.Item disabled={!bot.isActive} onClick={() => this.onPublicShareBotMenuClick(bot.id, bot.name)} eventKey="4.3">Share</NavDropdown.Item>}
                                                {<NavDropdown.Item disabled={!bot.isActive} onClick={() => this.onShareBotMenuClick(bot.id, bot.name)} eventKey="4.4">Send</NavDropdown.Item>}
                                                {<NavDropdown.Item disabled={!bot.isActive} onClick={() => this.createDuplicate(bot.id, bot.name)} eventKey="4.5">Copy</NavDropdown.Item>}
                                                {<NavDropdown.Item disabled={!bot.isActive} onClick={bot.isPaused ? () => this.unPauseBot(bot.id) : () => { this.onShowConfirmPauseBotModal(true, bot.name, bot.id) }} eventKey="4.6">{bot.isPaused ? "Resume" : "Pause"}</NavDropdown.Item>}
                                                {this.state.allowRunNow && !bot.isPaused && bot.isActive && <NavDropdown.Item onClick={this.runBotNow(bot.id, index, bot.counter)} eventKey="4.7">Run</NavDropdown.Item>}
                                                {this.state.allowScheduleRun && !bot.isPaused && bot.isActive && <NavDropdown.Item onClick={this.scheduleRunNowBot(bot.id, index, bot.counter)} eventKey="4.8">Schedule and Start Local Run</NavDropdown.Item>}
                                                {<NavDropdown.Item onSelect={() => this.onShowConfirmBotDeletedModal(true, bot.name, bot.id, index)} eventKey="4.9">Delete</NavDropdown.Item>}
                                            </NavDropdown>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="text-muted">Set Time</span>
                                        </Col>
                                        <Col className="botItemCounterTitle">
                                            <span className="text-muted ">Run Count</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {bot.runTimes && bot.runTimes.length > 0 ? (
                                                <span className="botItemTime">
                                                    {bot.runTimes[0].scheduledTime ? bot.upcomingRunTime ? "Run Schedule: " + bot.upcomingRunTime : bot.runTimes[0].scheduledTime : "Every " + bot.runTimes[0].frequency}<br />
                                                    {!bot.isPaused && bot.isActive ? "Booking: " + bot.bookingDate : ""}
                                                </span>
                                            ) : (<span>-</span>
                                            )}
                                        </Col>
                                        <Col style={{ textAlign: 'right' }}>
                                            <span className="botItemCounterData">{bot.counter}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className="d-flex justify-content-center">
                                            {bot.isLive ?
                                                (bot.isInfiniteRun ?
                                                    (<Button disabled={true} className="botRunItem botLive" size="lg" block>{"Pause Bot to Stop..."}</Button>)
                                                    :
                                                    (<Button disabled={true} className="botRunItem botLive" size="lg" block>{"In Progress..."}</Button>)
                                                ) :
                                                (bot.isInfiniteRun ?
                                                    (
                                                        <Button disabled={this.state.currentRunNowBotId == bot.id || !bot.isActive || bot.isPaused}
                                                            onClick={this.startInfiniteBots(bot.id, index, bot.counter)} className="botRunItem" size="lg" block>
                                                            {this.state.currentRunNowBotId == bot.id ? "Please wait..." : this.getButtonActiveText(bot.isActive, bot.isPaused)}</Button>)
                                                    : (<div><Form.Label style={{ width: "100%", textAlign: "center", fontWeight: "bold", fontSize: "26px", color: bot.isActive && !bot.isPaused ? "green" : "black" }} >
                                                        {this.state.currentRunNowBotId == bot.id ? "Please wait..." : this.state.currentScheduleRunNowBotId == bot.id ? "Bot starting soon..." : this.getButtonActiveText(bot.isActive, bot.isPaused)}
                                                    </Form.Label>{bot.reservationFeeAmount && bot.reservationFeeAmount > 0.00 ? ShowToolReservationFeeMessage(bot.reservationFeeAmount) : ""}</div>))

                                            }

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span style={{ color: this.state.runNowResultColor }} hidden={this.state.currentRunNowBotId != bot.id}>{this.state.runNowResult}</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </div>
                    )
                }
                < LiveBotFeedModal
                    show={this.state.liveBotFeedModalShow}
                    onHide={() => this.onShowLiveFeeddModal(false)}
                    connectionColor={this.state.connectionColor}
                    liveFeedMessage={this.state.liveFeedMessage}
                    connectionStatus={this.state.connectionStatus}
                />
                <DeleteBotModal
                    show={this.state.confirmBotDeleteModalShow}
                    onHide={() => this.onShowConfirmBotDeletedModal(false)}
                    botName={this.state.confirmDeleteBotName}
                    deleteBot={this.deleteBot}
                    confirmDeleteBotId={this.state.confirmDeleteBotId}
                    confirmDeleteBotIndex={this.state.confirmDeleteBotIndex}
                />
                <PublicShareBotModal
                    show={this.state.publicShareModalShow}
                    onHide={() => this.onShowPublicShareModal(false)}
                    botName={this.state.publicShareBotName}
                    shareUrl={this.state.publicShareUrl}
                    copyButtonText={this.state.publicShareCopyButtonText}
                    onCopyButtonClick={this.onCopyButtonClick}
                />
                <ShareBotModal
                    show={this.state.showSharedBotModal}
                    onHide={() => this.onShowShareBotModal(false)}
                    masterBotId={this.state.masterBotId}
                    mainBotDuplicateName={this.state.mainBotDuplicateName}
                />
                <LoginRequiredModal
                    show={this.state.showLoginAcceptSharedBotModal}
                    onHide={() => this.onShowLoginAcceptSharedBotModal(false)}
                    afterAcceptedComplete={this.afterAcceptedComplete}
                    showLoginTaskPassword={this.state.showLoginTaskPassword}
                    updateShowPasswordAcceptShareBot={this.updateShowPasswordAcceptShareBot}
                    id={this.state.acceptShareBotId}
                    showIsLoading={this.showIsLoading}
                />
                <DenyBotModal
                    show={this.state.confirmDenyBotModalShow}
                    onHide={() => this.onShowConfirmBotDenyModal(false)}
                    denyBotName={this.state.denyBotName}
                    denyBot={this.denyBot}
                />
                <PauseBotModal
                    show={this.state.confirmPauseBotModalShow}
                    onHide={() => this.onShowConfirmPauseBotModal(false)}
                    botName={this.state.confirmPauseBotName}
                    pauseBot={this.pauseBot}
                    confirmPauseBotId={this.state.confirmPauseBotId}
                />
                <CreateDuplicateBot
                    show={this.state.showConfirmDuplicateModal}
                    onHide={() => this.onShowConfirmDuplicateModal(false)}
                    oGId={this.state.oGId}
                    updateBotDataFromDuplicate={this.updateBotDataFromDuplicate}
                    mainBotDuplicateName={this.state.mainBotDuplicateName}
                />
                <HowToGuideModal
                    show={this.state.howToGuideModalShow}
                    onHide={() => this.onShowHowToGuideModal(false, false)}
                    isMobile={isMobileOnly}
                    name={this.state.name}
                    subscription={this.state.subscription}
                    backdrop={'static'}
                    closeAndCreate={() => this.onShowHowToGuideModal(false, true)}
                />
                <UpgradeNeededModal
                    show={this.state.showUpgradeNeededModal}
                    onHide={() => window.location = this.state.upgradeUrl}
                    message={this.state.upgradeMessage}
                    upgradeUrl={this.state.upgradeUrl}
                />
                <PlusSubscriptionModal
                    show={this.state.plusSubscriptionModalShow}
                    onHide={() => this.setState({ plusSubscriptionModalShow: false })}
                    selectedPlan={this.state.selectedPlan}
                    productIdHolder={this.state.productIdHolder}
                    handleSubscriptionModalShow={this.handleSubscriptionModalShow}
                    disablePlusModalButtons={this.state.disablePlusModalButtons}
                />
                <SubscriptionModal
                    show={this.state.subscriptionModalShow}
                    onHide={() => this.setState({ subscriptionModalShow: false })}
                    disableCompleteSubscribe={this.state.selectedPlan === this.state.currentSubscriptionName}
                    currentSubscriptionName={this.state.currentSubscriptionName}
                    selectedPlan={this.state.selectedPlan}
                    selectedPlanPrice={this.state.selectedPlanPrice}
                    completeButtonText={this.state.completeButtonText}
                />
                <ThankYouSubscription
                    show={this.state.thankYouSubscriptionModalShow}
                    onHide={() => this.handleThankYouModalShow(false, false)}
                    accountHolder={this.state.accountHolder}
                    accountEmailAddress={this.state.email}
                    subscriptionState={this.state.currentSubscriptionState}
                    subscriptionExpirationPst={this.state.subscriptionExpirationPst}
                    currentSubscriptionName={this.state.currentSubscriptionName}
                />
                <LoadingModal
                    show={this.state.isLoadingSpinner}
                    onHide={() => this.showIsLoading(false)}
                    backdrop={'static'}
                    keyboard={false}
                />
                <Finish
                    step={4}
                    showAnimation={true}
                    finishCreateBotModalShow={this.state.finishCreateBotModalShow}
                    handleFinishModalShow={this.handleFinishModalShow}
                    isBotComplete={true}
                />
                <BotUpgradeModal
                    show={this.state.botUpgradeModalShow}
                    onHide={() => this.handleBotUpgradeModalShow(false)}
                    currentCancellationBotDays={this.state.currentCancellationBotDays}
                    disableAddCancellationBot={this.state.disableAddCancellationBot}
                    addCancellationBotErrorAlert={this.state.addCancellationBotErrorAlert}
                    cancellationTotalPrice={this.state.cancellationTotalPrice}
                    cancellationBotParent={this.state.cancellationBotParent}
                    handleDayPickerOnChange={this.handleDayPickerOnChange}
                    addNewCancellationBot={this.addNewCancellationBot}
                    ShowToolTipMessage={ShowToolTipMessage}
                    clickPopOver={this.clickPopOver}
                    saveAddCancellationButtonText={this.state.saveAddCancellationButtonText}
                    currentBotsIncreaseAmount={this.state.currentBotsIncreaseAmount}
                    handleBotLibraryIncreaseOnChange={this.handleBotLibraryIncreaseOnChange}
                    subscription={this.state.subscription}
                />
            </Container>
        )
    }
}