import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Form from 'react-bootstrap/Form'
import dropin from 'braintree-web-drop-in'
import Badge from 'react-bootstrap/Badge'
import '../../css/subscription.css';
import greencheck from '../../assets/734FC27A-76FB-439B-964D-1AB2AAEE19BA.png';
import standardPlus from '../../assets/standardPlus.png';
import proPlus from '../../assets/proPlus.png';
import basicPlus from '../../assets/basicPlus.png';
import redX from '../../assets/Delete-icon.png';
import Button from 'react-bootstrap/Button';
import { getParameterByName } from '../../util/queryString';
import Modal from 'react-bootstrap/Modal'
import ApiClient from '../../util/ApiClient';
import moment from 'moment';
import Loader from "react-loader-spinner";
import TiktokPixel from 'tiktok-pixel';

TiktokPixel.init('CG99DTBC77UCLSGQSU3G');

function SubscriptionModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header>
                <Modal.Title>Purchase {props.selectedPlan} Plan <span style={{ color: 'green' }}>{props.selectedPlanPrice}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div id="paymentdetails"></div>
                    </Col>
                </Row>
                <Row>
                    <Button disabled={props.disableCompleteSubscribe} id="submit-button" variant="primary" block>{"Purchase"}</Button>
                </Row>
            </Modal.Body>
        </Modal>
    );
}
function PlusSubscriptionModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" className='subscriptionPlusDialog'>
            <Modal.Body>
                <Row>
                    <Col>
                        <Image src={props.selectedPlan == "Basic" ? basicPlus : props.selectedPlan == "Standard" ? standardPlus : props.selectedPlan == "Pro" ? proPlus : standardPlus} fluid />
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="info" disabled={props.disablePlusModalButtons} onClick={() => props.handleSubscriptionModalShow((props.productIdHolder + "_plus"), (props.selectedPlan + "Plus"))} block>Yes Go {props.selectedPlan} Plus!</Button>
                <Button variant="primary" disabled={props.disablePlusModalButtons} onClick={() => props.handleSubscriptionModalShow(props.productIdHolder, props.selectedPlan)} block>Continue with {props.selectedPlan}</Button>
            </Modal.Footer>
        </Modal>
    );
}


function SubscriptionEliteModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Purchase Elite Subscription<span style={{ color: 'green', marginLeft: '15px' }}>{'$' + props.selectedPlanPrice}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div id="elitepaymentdetails"></div>
                    </Col>
                </Row>
                <Row>
                    <Button id="submit-elite-button" variant="primary" block>Submit</Button>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

function ThankYouSubscription(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Thank you for your purchase</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <h3 style={{ textDecoration: 'underline', marginBottom: '12px' }}>Purchase Details</h3>
                    </Col>
                </Row>
                <Row className="summarydetails">
                    <Col>
                        <span>✅&nbsp;&nbsp;Account Holder: {props.accountHolder}</span>
                    </Col>
                </Row>
                <Row className="summarydetails">
                    <Col>
                        <span>✅&nbsp;&nbsp;Account Email Address: {props.accountEmailAddress}</span>
                    </Col>
                </Row>
                <Row className="summarydetails">
                    <Col>
                        <span>✅&nbsp;&nbsp;Purchase Date: {moment().format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
                    </Col>
                </Row>
                <Row className="summarydetails">
                    <Col>
                        <span>✅&nbsp;&nbsp;Purchase Item: {props.currentSubscriptionName}</span>
                    </Col>
                </Row>
                <Row className="summarydetails">
                    <Col>
                        <span>✅&nbsp;&nbsp;Subscription State: {props.subscriptionState}</span>
                    </Col>
                </Row>
                <Row className="summarydetails">
                    <Col>
                        <span>✅&nbsp;&nbsp;Next Billing Cycle: {moment(props.subscriptionExpirationPst).format("dddd, MMMM Do YYYY")}</span>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => { window.location = '/' }}>My Bots</Button>
            </Modal.Footer>
        </Modal>
    );
}
function CancelModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Cancel Bot-It Plan</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <h4 style={{ marginBottom: '20px' }}>We are sorry to see you go 😞</h4>
                        <p>Please let us know why you are cancelling your Bot-It Subscription</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control onChange={props.onCancelTextChange} as="textarea" value={props.cancelReasonText} rows="3" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button disabled={props.disableCancelButton} onClick={props.onCancelClick} variant="danger" style={{ marginTop: "30px", float: 'right' }}>Cancel Subscription</Button>
                    </Col>

                </Row>
            </Modal.Body>
        </Modal>
    );
}
function RemainingBotsModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Action Required</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <p>For privacy and protection, please delete all of your Bots before canceling your subscription on Bot-It.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>WARNING: Deleting a Bot cannot be reversed, all configuration and data will be erased.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={() => window.location = "/"} variant="danger" style={{ marginTop: "30px" }}>Delete My Bots</Button>
                    </Col>

                </Row>
            </Modal.Body>
        </Modal>
    );
}
function DowngradeSubscriptionErrorModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Subscription Downgrade Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        <h3>Your Bots require you to keep your current subscription. Downgrading cannot be completed at this time.</h3>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <Button onClick={props.onHide}>OK</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal >
    );
}
function ConfirmCancelSubscription(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Cancel confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <h3 style={{ textDecoration: 'underline', marginBottom: '12px' }}>Your subscription is now canceled</h3>
                    </Col>
                </Row>
                <Row className="summarydetails">
                    <Col>
                        <span>Account Holder: {props.accountHolder}</span>
                    </Col>
                </Row>
                <Row className="summarydetails">
                    <Col>
                        <span>Account Email Address: {props.accountEmailAddress}</span>
                    </Col>
                </Row>
                <Row className="summarydetails">
                    <Col>
                        <span>Cancel Date: {moment().format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
                    </Col>
                </Row>
                <Row className="summarydetails">
                    <Col>
                        <span>Cancel Status: {props.currentSubscriptionName}</span>
                    </Col>
                </Row>
                <Row className="summarydetails">
                    <Col>
                        <span>Subscription State: {props.subscriptionState}</span>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => { window.location = '/' }}>Go Home</Button>
            </Modal.Footer>
        </Modal>
    );
}
function SubscriptionConflictModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Bot-It Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <h4 style={{ marginBottom: '20px' }}>Apple or Google Subscription Detected</h4>
                        <p>We noticed that you are using Apple or Google to Subscribe with Bot-It. Please continue in the Bot-It App to update or modify your subscription.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={props.onSubscriptionConflictClick} variant="danger" style={{ marginTop: "30px", float: 'right' }}>Home</Button>
                    </Col>

                </Row>
            </Modal.Body>
        </Modal>
    );
}
function LoadingModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Body>
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={7000}
                        />
                    </Col>
                </Row>

            </Modal.Body>
        </Modal >
    );
}
@inject('rootStore')
@observer
export default class UserHome extends Component {
    constructor() {
        super();
        this.state = {
            subscriptionModalShow: false,
            plusSubscriptionModalShow: false,
            eliteSubscriptionModalShow: false,
            standardSubscriptionModalShow: false,
            proSubscriptionModalShow: false,
            thankYouSubscriptionModalShow: false,
            cancelSubscriptionModalShow: false,
            subscriptionConflictModalShow: false,
            remainBotsModalShow: false,
            downgradeSubscriptionErrorShow: false,
            cancelReasonText: '',
            confirmCancelSubscriptionModalShow: false,
            buttonAction: undefined,
            currentSubscriptionName: '',
            currentSubscriptionState: '',
            selectedPlan: '',
            accountHolder: '',
            accountEmailAddress: '',
            subscriptionExpirationPst: '',
            completeButtonText: '',
            productIdHolder: '',
            disablePlusModalButtons: false,
            hideBasicSubscription: false,
            isLoading: false,
            readyBot: null,
            discountCode: null,
            allowedCodes: [{ code: "golf2023", description: "50% Discount Applied to Standard Subscription", hidePro: true }, { code: "foregalsgolf2023", description: "50% Discount Applied to Standard Subscription", hidePro: true }, { code: "golfpro", description: "5% Discount Applied to Pro Subscription", hideStandard: true }],
            appliedCodeDetails: null,
            disablePro: false,
            disableStandard: false,
            sp: false,
            pre: false,
            selectedPlanPrice: ""
        };
    }
    componentDidMount() {
        var sub = getParameterByName('sub', window.location.href);
        const token = localStorage.getItem('token');
        if (!sub && !token) window.location = '/';
        if (sub == "basic")
            this.handleSubscriptionModalShow('com_botit_automation_premium_basic', 'Basic');
        else if (sub == "standard") {
            if (token)
                this.handleSubscriptionModalShow('com_botit_automation_premium_standard', 'Standard');
            else
                window.location = '/register?sub=standard';
        }
        else if (sub == "pro") {
            if (token)
                this.handleSubscriptionModalShow('com_botit_automation_premium_pro', 'Pro');
            else
                window.location = '/register?sub=pro';
        }
        else if (sub && sub.startsWith('com_botit_automation_premium_elite')) {
            ApiClient.get('subscription/braintree/elite/pricing/' + sub).then((response) => {
                if (response && response.data) {
                    this.setState({ selectedPlanPrice: response.data }, () => this.handleEliteSubscriptionModalShow(sub));
                }
            }).catch((error) => {
                this.setState({ disablePlusModalButtons: false })
            });
        }
        else if (sub && sub.startsWith('sp')) {
            if (token)
                this.setState({ sp: true }, () => this.handleSubscriptionModalShow(("com_botit_automation_premium_standard_plus"), ("StandardPlus")))
            else
                window.location = '/register?sub=sp';
        }
        else if (sub && sub.startsWith('pp')) {
            if (token)
                this.setState({ sp: true }, () => this.handleSubscriptionModalShow(("com_botit_automation_premium_pro_plus"), ("ProPlus")))
            else
                window.location = '/register?sub=pp';
        }
        var premiumBotSelected = getParameterByName('pre', window.location.href);
        if (premiumBotSelected == "true")
            this.setState({ pre: true });

        if (sub != "basic")
            this.setState({ hideBasicSubscription: true });
        var bot = localStorage.getItem("readybot");
        if (bot)
            this.setState({ readyBot: JSON.parse(bot) });

        this.getSubscription();
    }
    showIsLoading = (show) => {
        this.setState({ isLoading: show });
    }
    getSubscription = () => {
        ApiClient.get('user/profile').then((response) => {
            if (response) {
                if (response.data.subscriptionProvider == "APPLE" || response.data.subscriptionProvider == "GOOGLE") {
                    this.setState({ subscriptionConflictModalShow: true })
                }
                else {
                    this.setState({
                        currentSubscriptionName: response.data.subscriptionName,
                        currentSubscriptionState: response.data.subscriptionState,
                        accountHolder: response.data.name,
                        accountEmailAddress: response.data.emailAddress,
                        subscriptionExpirationPst: response.data.subscriptionExpirationPst
                    })
                }

            }
        }).catch((error) => {
        });
    }
    getButtonText = () => {
        if (this.state.currentSubscriptionName === 'Basic') this.setState({ completeButtonText: 'Upgrade Plan' });
        else if (this.state.currentSubscriptionName === 'Starter') this.setState({ completeButtonText: 'Upgrade Plan' });
        else if (this.state.currentSubscriptionName === 'Standard' || this.state.currentSubscriptionName === 'StandardPlus') {
            if (this.state.selectedPlan == 'Basic') this.setState({ completeButtonText: 'Downgrade Plan' });
            else if (this.state.selectedPlan == 'Pro' || this.state.selectedPlan == 'ProPlus' || this.state.selectedPlan == 'Standard' || this.state.selectedPlan == 'StandardPlus') this.setState({ completeButtonText: 'Upgrade Plan' });
        }
        else {
            if (this.state.selectedPlan == 'Basic' || this.state.selectedPlan == 'BasicPlus' || this.state.selectedPlan == 'Standard' || this.state.selectedPlan == 'StandardPlus') this.setState({ completeButtonText: 'Downgrade Plan' });
            else this.setState({ completeButtonText: 'Upgrade Plan' });
        }
    }
    isDowngradeSubscription = (displayName) => {
        if (this.state.currentSubscriptionName === 'Standard' || this.state.currentSubscriptionName === 'StandardPlus') {
            if (displayName == 'Basic' || displayName == 'BasicPlus') return true
        }
        else if (this.state.currentSubscriptionName === 'Pro' || this.state.currentSubscriptionName === 'ProPlus') {
            if (displayName == 'Basic' || displayName == 'BasicPlus' || displayName == 'Standard' || displayName == 'StandardPlus') return true;
        }
        else if (this.state.currentSubscriptionName === 'Elite') {
            if (displayName == 'Basic' || displayName == 'BasicPlus' || displayName == 'Standard' || displayName == 'StandardPlus' || displayName == 'Pro' || displayName == 'ProPlus') return true;
        }
        return false;
    }

    handleSubscriptionModalShow = (productId, displayName) => {
        this.setState({ disablePlusModalButtons: true }, () => {
            this.showIsLoading(true);
            if (this.isDowngradeSubscription(displayName)) {
                ApiClient.get('subscription/prevent/downgrade/' + displayName + '/' + this.state.currentSubscriptionName).then((response) => {
                    if (response && response.data) {
                        this.setState({ plusSubscriptionModalShow: false, downgradeSubscriptionErrorShow: true, disablePlusModalButtons: false })
                        return;
                    }
                    this.brainTreeSetup(productId, displayName);
                }).catch((error) => {
                    this.setState({ disablePlusModalButtons: false })
                });
            } else this.brainTreeSetup(productId, displayName);
        })
    }
    handleBeforeSubscriptionModalShow = (productId, displayName) => {
        this.setState({ plusSubscriptionModalShow: true, selectedPlan: displayName, productIdHolder: productId });
    }
    brainTreeSetup = (productId, displayName) => {
        ApiClient.get('subscription/braintree/client/token').then((response) => {
            this.showIsLoading(false);
            if (response && response.status == 200) {
                var price = "";
                switch (displayName) {
                    case "Basic":
                        price = "$3.99"
                        break;
                    case "BasicPlus":
                        price = "$7.99"
                        break;
                    case "Standard":
                        if (this.state.discountCode == "golf2023" || this.state.discountCode == "foregalsgolf2023")
                            price = "$5.00"
                        else
                            price = "$9.99"
                        break;
                    case "StandardPlus":
                        price = "$25.00"
                        break;
                    case "Pro":
                        if (this.state.discountCode == "golfpro")
                            price = "$94.99"
                        else
                            price = "$99.99"
                        break;
                    case "ProPlus":
                        price = "$275.00"
                        break;
                }
                this.setState({ disablePlusModalButtons: false, plusSubscriptionModalShow: false, subscriptionModalShow: true, selectedPlan: displayName, selectedPlanPrice: price }, () => { this.getButtonText(); this.handleThankYouModalShow(false); })
                var button = document.querySelector('#submit-button');
                dropin.create({
                    authorization: response.data,
                    container: '#paymentdetails'
                }, (createErr, instance) => {
                    button.addEventListener('click', () => {
                        instance.requestPaymentMethod((requestPaymentMethodErr, payload) => {
                            ApiClient.post('subscription/braintree/create?' + (this.state.sp && !this.state.pre ? 'isStandardPlus=true' : ''),
                                {
                                    payment_method_nonce: payload.nonce,
                                    product_id: productId,
                                    discountCode: this.state.discountCode
                                }).then((response) => {
                                    if (response && response.status == 200) {
                                        if (this.state.selectedPlanPrice && this.state.selectedPlanPrice.startsWith("$"))
                                            TiktokPixel.track('CompletePayment', { value: Number(this.state.selectedPlanPrice.replace(/[^0-9.-]+/g, "")), content_type: "purchase_" + this.state.selectedPlan, currency: 'USD' });
                                        this.getSubscription();
                                        this.setState({ subscriptionModalShow: false }, () => {
                                            if (this.state.readyBot) {
                                                ApiClient.post('bot/submit/answers', { ...this.state.readyBot }).then((response) => {
                                                    localStorage.removeItem("readybot");
                                                    this.setState({ thankYouSubscriptionModalShow: true })
                                                }).catch((error) => {
                                                    alert(JSON.stringify(error.response.data));
                                                });
                                            } else {
                                                this.setState({ thankYouSubscriptionModalShow: true })
                                            }
                                        })
                                    }
                                }).catch((error) => {
                                    alert(error)
                                });
                        });
                    });
                });
            }
        }).catch((error) => {
            this.showIsLoading(false);
            this.setState({ disablePlusModalButtons: false })
            alert(error)
        });
    }

    handleEliteSubscriptionModalShow = (productId) => {

        ApiClient.get('subscription/braintree/client/token').then((response) => {
            if (response && response.status == 200) {
                this.setState({ eliteSubscriptionModalShow: true, selectedPlan: 'Elite' }, () => { this.handleThankYouModalShow(false); })
                var button = document.querySelector('#submit-elite-button');
                dropin.create({
                    authorization: response.data,
                    paypal: {
                        flow: 'checkout',
                        amount: this.state.selectedPlanPrice,
                        currency: 'USD'
                    },
                    paypalCredit: {
                        flow: 'checkout',
                        amount: this.state.selectedPlanPrice,
                        currency: 'USD'
                    },
                    // googlePay: {
                    //     flow: 'checkout',
                    //     merchantId:'BCR2DN4TZCDJZ4YZ',
                    //     amount: this.state.selectedPlanPrice,
                    //     transactionInfo: { currencyCode: 'USD', totalPrice: this.state.selectedPlanPrice, checkoutOption: 'COMPLETE_IMMEDIATE_PURCHASE', totalPriceStatus: "FINAL" }
                    // },
                    container: '#elitepaymentdetails'
                }, (createErr, instance) => {
                    button.addEventListener('click', () => {
                        instance.requestPaymentMethod((requestPaymentMethodErr, payload) => {
                            ApiClient.post('subscription/braintree/create',
                                {
                                    payment_method_nonce: payload.nonce,
                                    product_id: productId,
                                    discountCode: this.state.discountCode
                                }).then((response) => {
                                    if (response && response.status == 200) {
                                        this.getSubscription();
                                        this.setState({ eliteSubscriptionModalShow: false, discountCode: null }, () => this.setState({ thankYouSubscriptionModalShow: true }))
                                    }
                                }).catch((error) => {
                                    alert(error)
                                });
                        });
                    });
                });
            }
        }).catch((error) => {
            alert(error)
        });
    }

    handleThankYouModalShow = (show) => {
        this.setState({ thankYouSubscriptionModalShow: show })
    }
    onClickGetBasic = () => {
        this.handleSubscriptionModalShow('com_botit_automation_premium_basic', 'Basic');
    }
    onClickGetStandard = () => {
        this.handleSubscriptionModalShow('com_botit_automation_premium_standard', 'Standard');
    }
    onClickGetPro = () => {
        this.handleSubscriptionModalShow('com_botit_automation_premium_pro', 'Pro');
    }
    onClickGetStandardPlus = () => {
        this.handleSubscriptionModalShow('com_botit_automation_premium_standard_plus', 'StandardPlus');
    }
    onClickGetProPlus = () => {
        this.handleSubscriptionModalShow('com_botit_automation_premium_pro_plus', 'ProPlus');
    }
    onclickGetElite = () => {
        window.open("tel:+13105702913");
    }
    onCancelClick = () => {
        if (window.confirm('Are you sure you want to cancel your account?')) {
            ApiClient.post('subscription/braintree/cancel', { cancelReason: this.state.cancelReasonText }).then((response) => {
                if (response) {
                    this.getSubscription()
                    this.setState({ cancelSubscriptionModalShow: false }, () => this.setState({ confirmCancelSubscriptionModalShow: true }));
                }
            }).catch((error) => {
                alert(error)
            });
        }
    }
    onSubscriptionConflictClick = () => {
        window.location = '/';
    }
    onCancelTextChange = (e) => {
        this.setState({ cancelReasonText: e.target.value });
    }
    handleDiscountCode = (e) => {
        if (this.state.allowedCodes.filter(q => q.code === e.target.value).length > 0) {
            var items = this.state.allowedCodes.filter(q => q.code === e.target.value)
            if (items)
                this.setState({ discountCode: e.target.value, appliedCodeDetails: items[0].description, disableStandard: items[0].hideStandard, disablePro: items[0].hidePro })
        } else {
            this.setState({ discountCode: null, appliedCodeDetails: null, disableStandard: false, disablePro: false })
        }
    }

    render() {

        const rootStore = this.props.rootStore;
        return (
            <Container>
                <Row>
                    <Col xs={12}>
                        <div>
                            <h1 style={{ textAlign: 'center', marginBottom: '40px' }}>Bot-It Subscriptions</h1>
                        </div>
                        <div>
                            <h4 style={{ textAlign: 'center', marginBottom: '40px' }}>Current Subscription Plan: <span style={{ textDecoration: 'underline' }}>{this.state.currentSubscriptionName}</span></h4>
                        </div>
                        <Container>
                            <Row className="d-flex justify-content-center">
                                <Col lg={5} hidden={this.state.sp || this.state.pre}>
                                    <Form.Group controlId="discountCode">
                                        <Form.Control type="text" placeholder='Apply Discount Code' onChange={this.handleDiscountCode} value={this.state.discountCode}>
                                        </Form.Control>
                                        <Form.Label className='muted' style={{ color: 'green' }} hidden={!this.state.appliedCodeDetails}>{this.state.appliedCodeDetails}</Form.Label>

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-center">
                                <Col xl={4} md={5} lg={4} className="pricebox p1" hidden={this.state.hideBasicSubscription}>
                                    <Row className="pricerow" style={{ marginBottom: '17px' }}>
                                        <Col xs={6} className="typeCol">
                                            <h4 class="subscriptionType">Basic <Badge className="trueg" variant="danger">Quick Start!</Badge></h4>
                                        </Col>
                                        <Col xs={6} className="priceCol">
                                            <Row>
                                                <Col xs={12}>
                                                    <h4 class="subscriptionPrice">$3.99<span style={{ fontSize: '14px' }} className="text-muted">/mo</span></h4>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} style={{ marginTop: '-9px' }}>

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Create 1 Bot</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xxs={8}>
                                            <span>Create Custom Bot</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Customer support</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Basic templates</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={redX}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Run every 6 or 12 hours</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={redX}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Standard templates</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={redX}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Pro templates</span>
                                        </Col>
                                    </Row>
                                    <Row className="d-flex align-items-start flex-column">
                                        <Button className="trueg" variant="info" size="lg" onClick={this.onClickGetBasic} block>Select Basic</Button>
                                    </Row>
                                </Col>
                                <Col xl={4} md={5} lg={4} className="pricebox p2" hidden={this.state.sp || this.state.pre}>
                                    <Row className="pricerow" style={{ marginBottom: '17px' }}>
                                        <Col xs={6} className="typeCol">
                                            <h4 class="subscriptionType">Standard <Badge className="trueg" variant="info">Most Popular!</Badge></h4>
                                        </Col>
                                        <Col xs={6} className="priceCol">
                                            <Row>
                                                <Col xs={12}>
                                                    <h4 class="subscriptionPrice">$9.99<span style={{ fontSize: '14px' }} className="text-muted">/mo</span></h4>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>5 Bots</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Customer Support</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button className="trueg" disabled={this.state.disableStandard} variant="info" size="lg" onClick={this.onClickGetStandard} block>Select Standard</Button>
                                    </Row>
                                </Col>   <Col xl={4} md={5} lg={4} className="pricebox p3" hidden={this.state.sp || this.state.pre}>
                                    <Row className="pricerow" style={{ marginBottom: '17px' }}>
                                        <Col xs={6} className="typeCol">
                                            <h4 class="subscriptionType">Pro <Badge className="trueg1" variant="Success">Best Deal!</Badge></h4>
                                        </Col>
                                        <Col xs={6} className="priceCol">
                                            <Row>
                                                <Col xs={12}>
                                                    <h4 class="subscriptionPrice">$99.99<span style={{ fontSize: '14px' }} className="text-muted">/year</span></h4>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>10 Bots</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Customer Support</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button className="trueg" disabled={this.state.disablePro} variant="info" size="lg" onClick={this.onClickGetPro} block>Select Pro</Button>
                                    </Row>
                                </Col>
                                <Col xl={4} md={5} lg={4} className="pricebox p2" hidden={!this.state.sp && !this.state.pre}>
                                    <Row className="pricerow" style={{ marginBottom: '17px' }}>
                                        <Col xs={6} className="typeCol">
                                            <h4 class="subscriptionType" style={{ fontSize: "18px" }}>Standard Plus<Badge className="trueg" variant="info">Most Popular!</Badge></h4>
                                        </Col>
                                        <Col xs={6} className="priceCol">
                                            <Row>
                                                <Col xs={12}>
                                                    <h4 class="subscriptionPrice">$25.00<span style={{ fontSize: '14px' }} className="text-muted">/mo</span></h4>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>5 Bots</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Extra Bot Power</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={redX}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Cancellation Bots</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Customer Support</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button className="trueg" disabled={this.state.disableStandard} variant="info" size="lg" onClick={this.onClickGetStandardPlus} block>Select Standard Plus</Button>
                                    </Row>
                                </Col>
                                <Col xl={4} md={5} lg={4} className="pricebox p3" hidden={!this.state.sp && !this.state.pre}>
                                    <Row className="pricerow" style={{ marginBottom: '17px' }}>
                                        <Col xs={6} className="typeCol">
                                            <h4 class="subscriptionType" style={{ fontSize: "18px" }}>Pro Plus<Badge className="trueg1" variant="Success">Best Deal!</Badge></h4>
                                        </Col>
                                        <Col xs={6} className="priceCol">
                                            <Row>
                                                <Col xs={12}>
                                                    <h4 class="subscriptionPrice">$275<span style={{ fontSize: '14px' }} className="text-muted">/year</span></h4>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>10 Bots</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Extra Bot Power</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Cancellation Bots</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Customer Support</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button className="trueg" disabled={this.state.disablePro} variant="info" size="lg" onClick={this.onClickGetProPlus} block>Select Pro Plus</Button>
                                    </Row>
                                </Col>
                                <Col xl={4} md={5} lg={4} className="pricebox p3" hidden={this.state.hideBasicSubscription}>
                                    <Row className="pricerow" style={{ marginBottom: '17px' }}>
                                        <Col xs={6} className="typeCol">
                                            <h4 class="subscriptionType">Elite <Badge className="trueg" variant="dark">Custom Pricing!</Badge></h4>
                                        </Col>
                                        <Col xs={6} className="priceCol">
                                            <Row>
                                                <Col xs={12}>
                                                    <h4 class="subscriptionPrice">Call Us</h4>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span><b>Free Custom Demo!</b></span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>We create all your Bots</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Run every 15 or 30 min</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Standard templates</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Pro templates</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>Custom templates</span>
                                        </Col>
                                    </Row>
                                    <Row className="detailRow">
                                        <Col xs={2} className="greencheck">
                                            <img src={greencheck}
                                                alt="" width="16" height="16" />
                                        </Col>
                                        <Col xs={8}>
                                            <span>1 on 1 support</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button className="trueg" variant="info" size="lg" onClick={this.onclickGetElite} block>Call Us</Button>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{ textAlign: "center" }}>
                                <Col>
                                    <a href="#" hidden={this.state.currentSubscriptionName === 'Starter'} onClick={(e) => {
                                        e.preventDefault(); ApiClient.get('bot/me/count').then((response1) => {
                                            if (response1 && response1.data > 0) {
                                                this.setState({ remainBotsModalShow: true })
                                            }
                                            else {
                                                this.setState({ cancelSubscriptionModalShow: true });
                                            }
                                        }).catch((error) => {
                                        });
                                    }}>Cancel</a>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <SubscriptionModal
                    show={this.state.subscriptionModalShow}
                    onHide={() => this.setState({ subscriptionModalShow: false })}
                    disableCompleteSubscribe={this.state.selectedPlan === this.state.currentSubscriptionName}
                    currentSubscriptionName={this.state.currentSubscriptionName}
                    selectedPlan={this.state.selectedPlan}
                    selectedPlanPrice={this.state.selectedPlanPrice}
                    completeButtonText={this.state.completeButtonText}
                />
                <PlusSubscriptionModal
                    show={this.state.plusSubscriptionModalShow}
                    onHide={() => this.setState({ plusSubscriptionModalShow: false })}
                    selectedPlan={this.state.selectedPlan}
                    productIdHolder={this.state.productIdHolder}
                    handleSubscriptionModalShow={this.handleSubscriptionModalShow}
                    disablePlusModalButtons={this.state.disablePlusModalButtons}
                />
                <SubscriptionEliteModal
                    show={this.state.eliteSubscriptionModalShow}
                    onHide={() => this.setState({ eliteSubscriptionModalShow: false })}
                    currentSubscriptionName={this.state.currentSubscriptionName}
                    selectedPlanPrice={this.state.selectedPlanPrice}
                />
                <ThankYouSubscription
                    show={this.state.thankYouSubscriptionModalShow}
                    onHide={() => this.handleThankYouModalShow(false, false)}
                    accountHolder={this.state.accountHolder}
                    accountEmailAddress={this.state.accountEmailAddress}
                    subscriptionState={this.state.currentSubscriptionState}
                    subscriptionExpirationPst={this.state.subscriptionExpirationPst}
                    selectedPlan={this.state.selectedPlan}
                    currentSubscriptionName={this.state.currentSubscriptionName}
                    hideBasicSubscription={this.state.hideBasicSubscription}
                />
                <CancelModal
                    show={this.state.cancelSubscriptionModalShow}
                    onHide={() => this.setState({ cancelSubscriptionModalShow: false })}
                    onCancelTextChange={this.onCancelTextChange}
                    cancelReasonText={this.state.cancelReasonText}
                    onCancelClick={this.onCancelClick}
                    disableCancelButton={this.state.currentSubscriptionName === 'Starter'}
                />
                <ConfirmCancelSubscription
                    show={this.state.confirmCancelSubscriptionModalShow}
                    onHide={() => this.setState({ confirmCancelSubscriptionModalShow: false })}
                    accountHolder={this.state.accountHolder}
                    accountEmailAddress={this.state.accountEmailAddress}
                    subscriptionState={this.state.currentSubscriptionState}
                    currentSubscriptionName={this.state.currentSubscriptionName}
                />
                <SubscriptionConflictModal
                    show={this.state.subscriptionConflictModalShow}
                    onHide={() => window.location = '/'}
                    onSubscriptionConflictClick={this.onSubscriptionConflictClick}
                />
                <RemainingBotsModal
                    show={this.state.remainBotsModalShow}
                    onHide={() => this.setState({ remainBotsModalShow: false })}
                />
                <DowngradeSubscriptionErrorModal
                    show={this.state.downgradeSubscriptionErrorShow}
                    onHide={() => this.setState({ downgradeSubscriptionErrorShow: false })}
                />
                <LoadingModal
                    show={this.state.isLoading}
                    onHide={() => this.showIsLoading(false)}
                    backdrop={'static'}
                    keyboard={false}
                />
            </Container >
        )
    }
}